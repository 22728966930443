import { FunctionComponent } from 'react';

interface GeneralTermsProps {
    // Definiere die Prop-Typen hier
}

const GeneralTerms: FunctionComponent<GeneralTermsProps> = () => {
    // Implementiere deine Komponente hier

    return (
        <div className='flex flex-col items-start justify-start w-full lg:w-1/3 mx-auto mt-[85px] gap-2'>
            <div className='text-left font-GeistBold text-3xl py-6'>
                <h1>Allgemeine Geschäftsbedingungen und Kundeninformationen</h1>
            </div>
            <span className='flex flex-col items-start justify-start gap-4'>
                <p>I. Allgemeine Geschäftsbedingungen</p>
                <p>§ 1 Grundlegende Bestimmungen</p>
                <p>(1) Die nachstehenden Geschäftsbedingungen gelten für Verträge, die Sie mit uns als Anbieter (Juwelier Bucher GbR) über die Internetseite https://www.juwelier-bucher.de schließen. Soweit nicht anders vereinbart, wird der Einbeziehung gegebenenfalls von Ihnen verwendeter eigener Bedingungen widersprochen.</p>
                <p>(2) Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann. Unternehmer ist jede natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder gewerblichen Tätigkeit handelt.</p>
                <hr />
                <p>§ 2 Zustandekommen des Vertrages</p>

                <p>(1) Gegenstand des Vertrages ist der Verkauf von Waren .</p>

                <p>(2) Bereits mit dem Einstellen des jeweiligen Produkts auf unserer Internetseite unterbreiten wir Ihnen ein verbindliches Angebot zum Abschluss eines Vertrages über das Online-Warenkorbsystem zu den in der Artikelbeschreibung angegebenen Bedingungen.</p>

                <p>(3) Der Vertrag kommt über das Online-Warenkorbsystem wie folgt zustande:
                    Die zum Kauf beabsichtigten Waren werden im "Warenkorb" abgelegt. Über die entsprechende Schaltfläche in der Navigationsleiste können Sie den "Warenkorb" aufrufen und dort jederzeit Änderungen vornehmen.
                    Nach Aufrufen der Seite "Kasse" und der Eingabe der persönlichen Daten sowie der Zahlungs- und Versandbedingungen werden Ihnen abschließend die Bestelldaten als Bestellübersicht angezeigt.</p>

                <p>Soweit Sie als Zahlungsart ein Sofortzahl-System (z.B. PayPal / PayPal Express, Amazon-Payments, Sofortüberweisung) nutzen, werden Sie entweder auf die Bestellübersichtsseite in unserem Online-Shop geführt oder auf die Internetseite des Anbieters des Sofortzahl-Systems weitergeleitet.
                    Erfolgt eine Weiterleitung zu dem jeweiligen Sofortzahl-System, nehmen Sie dort die entsprechende Auswahl bzw. Eingabe Ihrer Daten vor. Abschließend werden Ihnen auf der Internetseite des Anbieters des Sofortzahl-Systems oder nachdem Sie zurück in unseren Online-Shop geleitet wurden, die Bestelldaten als Bestellübersicht angezeigt.</p>

                <p>Vor Absenden der Bestellung haben Sie die Möglichkeit, die Angaben in der Bestellübersicht nochmals zu überprüfen, zu ändern (auch über die Funktion "zurück" des Internetbrowsers) bzw. die Bestellung abzubrechen.
                    Mit dem Absenden der Bestellung über die entsprechende Schaltfläche ("kaufen" oder ähnliche Bezeichnung) erklären Sie rechtsverbindlich die Annahme des Angebotes, wodurch der Vertrag zustande kommt.</p>

                <p>(4) Ihre Anfragen zur Erstellung eines Angebotes sind für Sie unverbindlich. Wir unterbreiten Ihnen hierzu ein verbindliches Angebot in Textform (z.B. per E-Mail), welches Sie innerhalb von 5 Tagen (soweit im jeweiligen Angebot keine andere Frist ausgewiesen ist) annehmen können.</p>

                <p>(5) Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.</p>

                <p>  § 3 Individuell gestaltete Waren</p>

                <p>(1) Sie stellen uns die für die individuelle Gestaltung der Waren erforderlichen geeigneten Informationen, Texte oder Dateien über das Online-Bestellsystem oder per E-Mail spätestens unverzüglich nach Vertragsschluss zur Verfügung. Unsere etwaigen Vorgaben zu Dateiformaten sind zu beachten.</p>

                <p> (2) Sie verpflichten sich, keine Daten zu übermitteln, deren Inhalt Rechte Dritter (insbesondere Urheberrechte, Namensrechte, Markenrechte) verletzen oder gegen bestehende Gesetze verstoßen. Sie stellen uns ausdrücklich von sämtlichen in diesem Zusammenhang geltend gemachten Ansprüchen Dritter frei. Das betrifft auch die Kosten der in diesem Zusammenhang erforderlichen rechtlichen Vertretung.</p>

                <p>  (3) Wir nehmen keine Prüfung der übermittelten Daten auf inhaltliche Richtigkeit vor und übernehmen insoweit keine Haftung für Fehler.</p>

                <p> § 4 Zurückbehaltungsrecht, Eigentumsvorbehalt</p>

                <p> (1) Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis handelt.</p>

                <p>(2)  Die Ware bleibt bis zur vollständigen Zahlung des Kaufpreises unser Eigentum.</p>

                <p>(3)  Sind Sie Unternehmer, gilt ergänzend Folgendes:</p>

                a) Wir behalten uns das Eigentum an der Ware bis zum vollständigen Ausgleich aller Forderungen aus der laufenden Geschäftsbeziehung vor. Vor Übergang des Eigentums an der Vorbehaltsware ist eine Verpfändung oder Sicherheitsübereignung nicht zulässig.

                b) Sie können die Ware im ordentlichen Geschäftsgang weiterverkaufen. Für diesen Fall treten Sie bereits jetzt alle Forderungen in Höhe des Rechnungsbetrages, die Ihnen aus dem Weiterverkauf erwachsen, an uns ab, wir nehmen die Abtretung an. Sie sind weiter zur Einziehung der Forderung ermächtigt. Soweit Sie Ihren Zahlungsverpflichtungen nicht ordnungsgemäß nachkommen, behalten wir uns allerdings vor, die Forderung selbst einzuziehen.

                c) Bei Verbindung und Vermischung der Vorbehaltsware erwerben wir Miteigentum an der neuen Sache im Verhältnis des Rechnungswertes der Vorbehaltsware zu den anderen verarbeiteten Gegenständen zum Zeitpunkt der Verarbeitung.

                d) Wir verpflichten uns, die uns zustehenden Sicherheiten auf Ihr Verlangen insoweit freizugeben, als der realisierbare Wert unserer Sicherheiten die zu sichernde Forderung um mehr als 10% übersteigt. Die Auswahl der freizugebenden Sicherheiten obliegt uns.

                § 5 Gewährleistung

                <p>(1) Es bestehen die gesetzlichen Mängelhaftungsrechte.</p>

                <p>(2) Als Verbraucher werden Sie gebeten, die Sache bei Lieferung umgehend auf Vollständigkeit, offensichtliche Mängel und Transportschäden zu überprüfen und uns sowie dem Spediteur Beanstandungen schnellstmöglich mitzuteilen. Kommen Sie dem nicht nach, hat dies keine Auswirkung auf Ihre gesetzlichen Gewährleistungsansprüche.</p>

                <p>(3) Soweit Sie Unternehmer sind, gilt abweichend von den vorstehenden Gewährleistungsregelungen:</p>

                a)  Als Beschaffenheit der Sache gelten nur unsere eigenen Angaben und die Produktbeschreibung des Herstellers als vereinbart, nicht jedoch sonstige Werbung, öffentliche Anpreisungen und Äußerungen des Herstellers.

                b)  Bei Mängeln leisten wir nach unserer Wahl Gewähr durch Nachbesserung oder Nachlieferung. Schlägt die Mangelbeseitigung fehl, können Sie nach Ihrer Wahl Minderung verlangen oder vom Vertrag zurücktreten. Die Mängelbeseitigung gilt nach erfolglosem zweiten Versuch als fehlgeschlagen, wenn sich nicht insbesondere aus der Art der Sache oder des Mangels oder den sonstigen Umständen etwas anderes ergibt. Im Falle der Nachbesserung müssen wir nicht die erhöhten Kosten tragen, die durch die Verbringung der Ware an einen anderen Ort als den Erfüllungsort entstehen, sofern die Verbringung nicht dem bestimmungsgemäßen Gebrauch der Ware entspricht.

                c)  Die Gewährleistungsfrist beträgt ein Jahr ab Ablieferung der Ware. Die Fristverkürzung gilt nicht:

                <p>- für uns zurechenbare schuldhaft verursachte Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und bei vorsätzlich oder grob fahrlässig verursachten sonstigen Schäden;</p>
                <p>                - soweit wir den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache übernommen haben;
                </p>                <p>- bei Sachen, die entsprechend ihrer üblichen Verwendungsweise für ein Bauwerk verwendet worden sind und dessen Mangelhaftigkeit verursacht haben;</p>
                <p>                - bei gesetzlichen Rückgriffsansprüchen, die Sie im Zusammenhang mit Mängelrechten gegen uns haben.
                </p>
                <p>§ 6 Rechtswahl</p>

                <p>(1)  Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch zwingende Bestimmungen des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip).</p>

                <p>(2)  Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung.</p>
                II. Kundeninformationen

                1. Identität des Verkäufers

                Juwelier Bucher GbR
                Weinholdstraße 33
                08468 Reichenbach
                Deutschland
                Telefon: 0 37 65/ 1 28 02
                E-Mail: info@juwelier-bucher.de


                Alternative Streitbeilegung:
                Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.

                Wir sind bereit, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen.

                2. Informationen zum Zustandekommen des Vertrages

                Die technischen Schritte zum Vertragsschluss, der Vertragsschluss selbst und die Korrekturmöglichkeiten erfolgen nach Maßgabe der Regelungen "Zustandekommen des Vertrages" unserer Allgemeinen Geschäftsbedingungen (Teil I.).

                3. Vertragssprache, Vertragstextspeicherung

                3.1. Vertragssprache ist deutsch .

                3.2. Der vollständige Vertragstext wird von uns nicht gespeichert. Vor Absenden der Bestellung  über das Online - Warenkorbsystem  können die Vertragsdaten über die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden. Nach Zugang der Bestellung bei uns werden die Bestelldaten, die gesetzlich vorgeschriebenen Informationen bei Fernabsatzverträgen und die Allgemeinen Geschäftsbedingungen nochmals per E-Mail an Sie übersandt.

                3.3. Bei Angebotsanfragen außerhalb des Online-Warenkorbsystems erhalten Sie alle Vertragsdaten im Rahmen eines verbindlichen Angebotes in Textform übersandt, z.B. per E-Mail, welche Sie ausdrucken oder elektronisch sichern können.

                4. Verhaltenskodizes

                4.1. Wir haben uns den Käufersiegel-Qualitätskriterien der Händlerbund Management AG unterworfen, einsehbar unter: https://www.haendlerbund.de/de/downloads/kaeufersiegel/kaeufersiegel-zertifizierungskriterien.pdf.

                5. Wesentliche Merkmale der Ware oder Dienstleistung

                Die wesentlichen Merkmale der Ware und/oder Dienstleistung finden sich im jeweiligen Angebot.

                6. Preise und Zahlungsmodalitäten

                6.1. Die in den jeweiligen Angeboten angeführten Preise sowie die Versandkosten stellen Gesamtpreise dar. Sie beinhalten alle Preisbestandteile einschließlich aller anfallenden Steuern.

                6.2. Die anfallenden Versandkosten sind nicht im Kaufpreis enthalten. Sie sind über eine entsprechend bezeichnete Schaltfläche auf unserer Internetpräsenz oder im jeweiligen Angebot aufrufbar, werden im Laufe des Bestellvorganges gesondert ausgewiesen und sind von Ihnen zusätzlich zu tragen, soweit nicht die versandkostenfreie Lieferung zugesagt ist.

                6.3. Entstandene Kosten der Geldübermittlung (Überweisungs- oder Wechselkursgebühren der Kreditinstitute) sind von Ihnen in den Fällen zu tragen, in denen die Lieferung in einen EU-Mitgliedsstaat erfolgt, die Zahlung aber außerhalb der Europäischen Union veranlasst wurde.

                6.4. Die Ihnen zur Verfügung stehenden Zahlungsarten sind unter einer entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz oder im jeweiligen Angebot ausgewiesen.

                6.5. Soweit bei den einzelnen Zahlungsarten nicht anders angegeben, sind die Zahlungsansprüche aus dem geschlossenen Vertrag sofort zur Zahlung fällig.

                7. Lieferbedingungen

                7.1. Die Lieferbedingungen, der Liefertermin sowie gegebenenfalls bestehende Lieferbeschränkungen finden sich unter einer entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz oder im jeweiligen Angebot.

                7.2. Soweit Sie Verbraucher sind ist gesetzlich geregelt, dass die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der verkauften Sache während der Versendung erst mit der Übergabe der Ware an Sie übergeht, unabhängig davon, ob die Versendung versichert oder unversichert erfolgt. Dies gilt nicht, wenn Sie eigenständig ein nicht vom Unternehmer benanntes Transportunternehmen oder eine sonst zur Ausführung der Versendung bestimmte Person beauftragt haben.

                Sind Sie Unternehmer, erfolgt die Lieferung und Versendung auf Ihre Gefahr.

                8. Gesetzliches Mängelhaftungsrecht

                Die Mängelhaftung richtet sich nach der Regelung "Gewährleistung" in unseren Allgemeinen Geschäftsbedingungen (Teil I).

                Diese AGB und Kundeninformationen wurden von den auf IT-Recht spezialisierten Juristen des Händlerbundes erstellt und werden permanent auf Rechtskonformität geprüft. Die Händlerbund Management AG garantiert für die Rechtssicherheit der Texte und haftet im Falle von Abmahnungen. Nähere Informationen dazu finden Sie unter: https://www.haendlerbund.de/de/leistungen/rechtssicherheit/agb-service.

                letzte Aktualisierung: 27.10.2020
            </span>
        </div>
    );
}

export default GeneralTerms;